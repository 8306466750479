import React from "react"

const JobBody = ({ bodyText }) => {
    return (
        <div>
            <div className="container-fluid mt-80 mt-26-mobile">
                <div className="row">
                    <div className="col-md-8 offset-md-3"
                    dangerouslySetInnerHTML={{ __html: bodyText }} 
                    ></div>
                </div>
            </div>
        </div>
    )
}

export default JobBody