import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import JobTitleAndButton from "../components/jobTitleAndButton"
import JobBody from "../components/jobBody"
import JobInfoRow from "../components/jobInfoRow"
import JobInfoText from "../components/jobInfoText"
import JobApplicationText from "../components/jobApplicationText"
import Button from "../components/button"

export default function JobPage({ data: { page } }) {
  return (
    <Layout>
        <div className="container-fluid pt-60 pt-24-mobile">
          <div className="row">
            <div className="offset-md-1 col-md-11 mb-36">
              <Button type="backArrowLink" url="/hiring" text="Back to listings" newTab={false} />
            </div>
          </div>
        </div>
        <JobTitleAndButton title={page.title} appUrl={page.applicationUrl} />
        <JobInfoRow location={page.location} type={page.jobType} area={page.area}/>
        <JobBody bodyText={page.description} />
        <JobInfoText text={page.equalOpportunityText} />
        <JobApplicationText text={page.instructionsCallOut} />
    </Layout>
  )
}

export const query = graphql`
  query PostBySlug($slug: String) {
    page: datoCmsJobOpportunity(slug: { eq: $slug }) {
      id
      title
      applicationUrl
      description
      location
      area 
      jobType
      equalOpportunityText
      instructionsCallOut
      seo {
        title
        description
        image {
          url
        }
      }
    }
  }
`

export const Head = ({ data }) => {
  return (
    <Seo 
      title={data.page.seo.title}
      description={data.page.seo.description}
      image={data.page.seo.image}
    />
  )
}