// extracted by mini-css-extract-plugin
export var blackish = "#121117";
export var colors = "\"../../assets/css/_colors.css\"";
export var fonts = "\"../../assets/css/_fonts.css\"";
export var gtWalsheim = "'gt-walsheim'";
export var lightBg = "#FFF8E8";
export var lightGreyBg = "#EAEAEA";
export var lightOrange = "#FFDCB7";
export var lightPurple = "#E1DBFF";
export var lightYellowBg = "#FFF0BD";
export var lightestPurple = "#E2DDFF";
export var mediumOrange = "#FFB96F";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var text = "styles-module--text--99976";
export var text_container = "styles-module--text_container--cc62e";
export var yellowBtn = "#FFE07B";