// extracted by mini-css-extract-plugin
export var back__arrow__link = "styles-module--back__arrow__link--7dfa9";
export var blackish = "#121117";
export var colors = "\"../../assets/css/_colors.css\"";
export var fonts = "\"../../assets/css/_fonts.css\"";
export var gtWalsheim = "'gt-walsheim'";
export var jumbo__purple__btn = "styles-module--jumbo__purple__btn--d4ea9";
export var lightBg = "#FFF8E8";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var purple__btn = "styles-module--purple__btn--34a9c";
export var purple__marching__ants = "styles-module--purple__marching__ants--6ec41";
export var strokeAnim = "styles-module--strokeAnim--11bf6";
export var white__btn = "styles-module--white__btn--1a6dd";
export var yellowBtn = "#FFE07B";