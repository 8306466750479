import React from "react"
import * as styles from "./styles.module.css"

const JobInfoRow = ({ location, type, area }) => {
    return (
        <div className={`container-fluid ${styles.mobile_margin}`}>
        <div className="row">
            <div className="col-md-3 offset-md-1">
                <p className={styles.title}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.2667 6.13333C13.2667 10.3333 8.13333 15 8.13333 15C8.13333 15 3 10.3333 3 6.13333C3 4.77189 3.54083 3.46621 4.50352 2.50352C5.46621 1.54083 6.77189 1 8.13333 1C9.49478 1 10.8005 1.54083 11.7631 2.50352C12.7258 3.46621 13.2667 4.77189 13.2667 6.13333V6.13333Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.13411 8.46647C9.42278 8.46647 10.4674 7.4218 10.4674 6.13314C10.4674 4.84447 9.42278 3.7998 8.13411 3.7998C6.84545 3.7998 5.80078 4.84447 5.80078 6.13314C5.80078 7.4218 6.84545 8.46647 8.13411 8.46647Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> 
                    Location
                </p> 
                <p>{location}</p>
            </div>
            <div className="col-md-3">
                <p className={styles.title}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5 15.5C12.5 15.5 12.5 14.716 12.5 14.5C12.5 11.657 11.545 9.11 10 8C11.545 6.89 12.5 4.343 12.5 1.5C12.5 1.284 12.5 0.5 12.5 0.5" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3.5 15.5C3.5 15.5 3.5 14.716 3.5 14.5C3.5 11.657 4.455 9.11 6 8C4.455 6.89 3.5 4.343 3.5 1.5C3.5 1.284 3.5 0.5 3.5 0.5" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1.5 0.5H14.5" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1.5 15.5H14.5" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Type
                </p>
                <p>{type}</p>
            </div>
            <div className="col-md-3">
                <p className={styles.title}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1546_4416)">
                        <path d="M1 5V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15 5V2C15 1.73478 14.8946 1.48043 14.7071 1.29289C14.5196 1.10536 14.2652 1 14 1H11" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1 11V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15 11V14C15 14.2652 14.8946 14.5196 14.7071 14.7071C14.5196 14.8946 14.2652 15 14 15H11" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 5V11" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5 8H11" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1546_4416">
                        <rect width="16" height="16" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                    Area
                </p>
                <p>{area}</p>
            </div>
        </div>
        </div>
    )
}

export default JobInfoRow