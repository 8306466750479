import React from "react"
import Button from "../button"
import * as styles from "./styles.module.css"

const JobTitleAndButton = ({ title, appUrl }) => {
    return (
        <div className="container-fluid">
            <div className="row align-center">
                <div className="col-md-7 offset-md-1">
                    <h1 className="m-0 h2 text-left">{title}</h1>
                </div>
                <div className={`col-md-3 text-right ${styles.hide_mobile}`}>
                    <Button 
                        type="purple" 
                        url={appUrl} 
                        text="Apply to WholeMind" 
                        newTab={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default JobTitleAndButton