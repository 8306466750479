import React, { useEffect, useState } from "react";
import * as styles from "./styles.module.css";

const Button = ({ type, url, text, newTab }) => {
  // Get current window dimensions
  const [dimensions, setDimensions] = useState();
  useEffect(() => {
    const listener = window.addEventListener("resize", handleResize);
    setDimensions({ width: window.innerWidth, height: window.innerHeight });

    return () => window.removeEventListener(listener);
  }, []);

  function handleResize() {
    setDimensions({ width: window.innerWidth, height: window.innerHeight });
  }

  switch (type) {
    case "transparent":
      return (
        <a
          href={url}
          target={newTab ? "_blank" : "_self"}
          className="transparent__btn"
        >
          {text}
        </a>
      );
    case "jumboPurple":
      return (
        <a
          href={url}
          target={newTab ? "_blank" : "_self"}
          className={styles.jumbo__purple__btn}
        >
          {text}
        </a>
      );
    case "purple":
      return (
        <a
          href={url}
          target={newTab ? "_blank" : "_self"}
          className={styles.purple__btn}
        >
          {text}
        </a>
      );
    case "white":
      return (
        <a
          href={url}
          target={newTab ? "_blank" : "_self"}
          className={styles.white__btn}
        >
          {text}
        </a>
      );
    case "purpleMarchingAnts":
      return (
        dimensions && (
          <>
            {
              dimensions.width < 500 ? 
                <a
                  href={url}
                  target={newTab ? "_blank" : "_self"}
                  className={styles.purple__btn}
                >
                  {text}
                </a>
              :
                <a
                href={url}
                target={newTab ? "_blank" : "_self"}
                className={styles.purple__marching__ants}
                >
                  <div>
                    <svg viewBox="500 100">
                      <rect
                        x="1"
                        y="1"
                        ry={dimensions.width < 991 ? 15 : 20}
                        rx={dimensions.width < 991 ? 15 : 20}
                      />
                    </svg>
                    <p>{text}</p>
                  </div>
                </a>
            }
          </>
        )
      );
    case "backArrowLink":
      return (
        <a
          href={url}
          target={newTab ? "_blank" : "_self"}
          className={styles.back__arrow__link}
        >
          {text}
        </a>
      );
    default:
      return <></>;
  }
};

export default Button;
