import React from "react"
import * as styles from "./styles.module.css"

const JobApplicationText = ({ text }) => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-7 offset-md-3">
                    <div className={styles.text_container}>
                        <p className={styles.text}>{text}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobApplicationText