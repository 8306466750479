import React from "react"
import * as styles from "./styles.module.css"

const JobInfoText = ({ text }) => {
    return (
        <div id="apply" className="container-fluid">
            <div className="row">
                <div className="col-md-7 offset-md-3">
                    <p className={styles.info_text}>{text}</p>
                </div>
            </div>
        </div>
    )
}

export default JobInfoText